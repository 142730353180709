body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
    margin: 0;
    padding: 0
}

body {
    font: 12px "宋体", "Arial Narrow", HELVETICA;
    background: #fff;
    -webkit-text-size-adjust: 100%;
}

a {
    color: #2d374b;
    text-decoration: none
}

a:hover {
    color: #cd0200;
    text-decoration: underline
}

em {
    font-style: normal
}

li {
    list-style: none
}

img {
    border: 0;
    vertical-align: middle
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

p {
    word-wrap: break-word
}

.cl:after {
    content: "";
    display: block;
    overflow: hidden;
    clear: both;
    height: 0;
    visibility: hidden;
}

.cl {
    zoom: 1;
}

.Flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
}